import {
  Title3,
  TextPresetProps,
  tokens,
  makeStyles,
} from "@fluentui/react-components";

interface IAppHeader {
  text: string;
}

const useStyles = makeStyles({
  root: {
    marginBottom: tokens.spacingVerticalXL,
    whiteSpace: "pre-line" as "pre-line",
    textAlign: "center" as "center",
  },
});

export default function AppHeader(props: IAppHeader & TextPresetProps) {
  const classes = useStyles();
  return <Title3 className={classes.root}>{props.text}</Title3>;
}
