import { toast } from "react-hot-toast";
import { tokens } from "@fluentui/react-components";
import css from "csstype";

const styles: css.Properties = {
  color: tokens.colorPaletteRedForeground1,
  background: tokens.colorPaletteRedBackground1,
  borderRadius: tokens.borderRadiusMedium,
};

export default function appErrorToast(text: string) {
  return toast(text, { style: styles });
}
