import { Spinner } from "@fluentui/react-components";
import InAndOutCard from "../../../components/common/InAndOut/InAndOutCard";
import { useCallback, useEffect, useState } from "react";
import {
  GetInAndOutAsync,
  WorktimeInAndOut,
} from "../../../services/worktime.service";
import "./InAndOutHistoryStyles.css";
import InAndOutBar from "../../../components/common/InAndOut/InAndOutBar/InAndOutBar";
import dataTimeService from "../../../services/dataTime.service";
import BackButton from "../../../components/common/BackButton";
import { mobileWidth } from "../../../services/Helpers/HelperService";
import moment from "moment";

const InAndOutHistory = () => {
  const [inAndOuts, setInAndOuts] = useState<WorktimeInAndOut[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState<string>(dataTimeService.todayString());
  const [dateTo, setDateTo] = useState<string>();
  const [totalWorkTime, setTotalWorkTime] = useState<string>("00:00");

  const fetchInAndOut = useCallback((dateFrom: Date, dateTo: Date | null | undefined) => {
    setIsLoading(true);
    GetInAndOutAsync({ dateFrom: dateFrom, dateTo: dateTo})
      .then((response) => {
        setInAndOuts(response.worktimes);
        setTotalWorkTime(response.totalWorkTime);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onDateFromChanged = useCallback((dateFrom: string) => {
    setDateFrom(dateFrom);
  }, []);

  const onDateToChanged = useCallback((dateTo: string) => {
    setDateTo(dateTo);
  }, []);

  const inAndOutsMapped = useCallback(
    () =>
      inAndOuts.map((inAndOut, index) => (
        <InAndOutCard key={`in-out-item-${index}`} inAndOut={inAndOut} />
      )),
    [inAndOuts]
  );

  useEffect(() => {
    if ( moment(dateFrom).isValid() && dateTo) {
      fetchInAndOut(new Date(dateFrom), new Date(dateTo));
    }
    else if (moment(dateFrom).isValid()) {
      fetchInAndOut(new Date(dateFrom), null);
    }
  }, [fetchInAndOut, dateFrom]);

  return (
    <>
      <InAndOutBar
        onDateFromChanged={onDateFromChanged}
        dateFrom={dateFrom}
        onDateToChanged={onDateToChanged}
        dateTo={dateTo}
        totalWorkTime={totalWorkTime}
      />
      {isLoading ? (
        <div className="spinner-container">
          <Spinner size="huge" />
        </div>
      ) : (
        <>
          <div className="inAndOutHistoryList">
            {inAndOuts.length === 0 && !isLoading ? (
              <h1 className="inAndOutHistoryInformation">
                Jeszcze nie rozpocząłeś pracy!
              </h1>
            ) : (
              <div className="timeline">{inAndOutsMapped()}</div>
            )}
          </div>
          {window.innerWidth < mobileWidth && <BackButton />}
        </>
      )}
    </>
  );
};

export default InAndOutHistory;
