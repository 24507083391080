import axiosWithoutHandler from "../utils/axiosWithoutHandler";
import appErrorToast from "../components/common/AppErrorToast";
import moment from "moment";
import apiService from "./api.service";

const pathPrefix = `${process.env.REACT_APP_SERVER_URL}/api`;

class ReportsService {

    fetchReportBase(path: string, filename: string, setIsLoading: (value: boolean) => void) {
      axiosWithoutHandler
      .get(path, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}${moment().format("DDMMYYYYhhmmss")}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsLoading(false);
      })
      .catch(() => {
        appErrorToast(
          "Nie można wygenerować raportu z daty przed utworzeniem organizacji"
        );
        setIsLoading(false);
      });
    }

    extractDateParts = (dateString: string) => {
      const year = moment(dateString).year().toString();
      const rest = dateString.substring(5);

      const match = rest.match(/^(?:(\d{2})|W(\d{2})|(\d{2})-(\d{2}))$/);
      if (!match) throw new Error('Invalid date format');
    
      const [, month, week, monthPart, day] = match;
      return { year, month: month || monthPart, week, day };
    };

    removeLeadingZeros = (str: string): string => str.replace(/^0+/, '');

    generateMonthlyReport(year: number, month: number, setIsLoading: (value: boolean) => void) {
        this.fetchReportBase(
          `${pathPrefix}/reports/overtime-realizations?month=${month}&year=${year}`,
          'RaportNadgodzin_',
          setIsLoading
        );
      }

      generateWorktimeReport(
        dateValue: string,
        setIsLoading: (value: boolean) => void,
        userId?: string, 
      ){
        let pathSuffix = userId ? `/reports/worktime-per-user` : `/reports/worktime-cumulative`;
        let path = `${pathPrefix}${pathSuffix}`;
        const params = [];
        const { year, month, day, week } = this.extractDateParts(dateValue);

        if (userId) params.push({ key: "userId", value: userId });
        if (year)   params.push({ key: "year",   value: year });
        if (month)  params.push({ key: "month",  value: month });
        if (day)    params.push({ key: "day",    value: day });
        if (week)   params.push({ key: "week",   value: week });

        if (params.length > 0) {
          path = apiService
            .setPathParams(path, params)
            .replace('&', '?');
        }

        this.fetchReportBase(
          path, 
          userId ? "RaportPracownika_" : "RaportZbiorczy_", 
          setIsLoading
        );
      }
}

const reportsService = new ReportsService();
export default reportsService;
