import { Input, Label, Switch } from "@fluentui/react-components";
import "./InAndOutBarStyles.css";
import { dataTimeService } from "../../../../services";
import { useCallback, useState } from "react";
import moment from "moment";

interface IInAndOutBarProps {
  onDateFromChanged: (dateFrom: string) => void;
  dateFrom: string;
  onDateToChanged: (dateFrom: string) => void;
  dateTo: string | undefined;
  totalWorkTime: string;
}

const inputDateFormat = 'yyyy-MM-DD';

const InAndOutBar = (props: IInAndOutBarProps) => {
  const [showDateTo, setShowDateTo] = useState(false);

  const onDataFromChanged = useCallback((date: string) => {
    props.onDateFromChanged(date);
  }, []);

  const onDataToChanged = useCallback((date: string) => {
    props.onDateToChanged(date);
  }, []);

  const switchDateRange = (item: React.ChangeEvent<HTMLInputElement>) => {
    if (item.target.checked) {
      const weekBeforeDate = moment(props.dateFrom).subtract(1, 'week').format(inputDateFormat).toString();
      props.onDateFromChanged(weekBeforeDate); 
      props.onDateToChanged(dataTimeService.todayString())
    } else {
      props.onDateFromChanged(moment().format(inputDateFormat).toString());
      props.onDateToChanged("")
    }
    setShowDateTo(item.target.checked);
  }

  return (
    <div className="callendar-bar">
      <div className="callendar-bar-input">
        <div className="input-with-label">
          <Label className="callendar-bar-label">Data</Label>
          <Input
            onChange={(date) => onDataFromChanged(date.target.value)}
            type="date"
            value={props.dateFrom}
            max={dataTimeService.todayString()}
          />
        </div>
        <div className="input-with-label">
          <Label className="callendar-bar-label">Przedział</Label>
          <Switch onChange={switchDateRange} />
        </div>
        {showDateTo && (
          <div className="input-with-label">
            <Label className="callendar-bar-label">Data do</Label>
            <Input
              onChange={(date) => onDataToChanged(date.target.value)}
              type="date"
              value={props.dateTo}
              min={props.dateFrom}
              max={dataTimeService.todayString()}
            />
          </div>
        )}
      </div>
      <div className="callendar-bar-time">
        <div className="callendar-bar-label">Czas pracy:</div>
        <div>{props.totalWorkTime ?? "00:00"}</div>
      </div>
    </div>
  );
};

export default InAndOutBar;
