import { createContext, useState, useEffect } from "react";
import {  IUserDataResponse } from "../../services/types/users";
import { LandingPageLayout } from "../../components/layouts";
import { userRoleEnum } from "../../utils/enums";
import { AppLoader } from "../../components/common";
import { app } from "@microsoft/teams-js";
import usersService from "../../services/users.service";
import WarningPage from "../WarningPage";
import AppInstruction from "../Shared/AppInstruction";

interface IUserContext {
  userData: IUserDataResponse;
  setUserData: React.Dispatch<React.SetStateAction<IUserDataResponse>>;
}

const UserContext = createContext<IUserContext>({
  userData: {} as IUserDataResponse,
  setUserData: () => {},
});
export function UserContextProvider(props: { children: React.ReactNode }) {
  const [userData, setUserData] = useState({} as IUserDataResponse);
  const [isLoading, setIsLoading] = useState(true);
  const [showInfo, setShowInfo] = useState(false);

  const getSelf = async () => {
    try {
      await app.initialize();
    } catch (error) {
      setShowInfo(true);
      return;
    }
    await usersService.getSelf().then((data: IUserDataResponse) => {
      const roleFromData = data.data.roles && data.data.roles[0];
      const savedWorkContext = localStorage.getItem('workContext');
      const baseRole = savedWorkContext 
        ?? roleFromData
        ?? userRoleEnum.Employee;

      data.data.baseRole = Object
        .values(userRoleEnum)
        .includes(baseRole as userRoleEnum) 
        ? baseRole 
        : userRoleEnum.Employee;

      if(!savedWorkContext) localStorage
        .setItem('workContext', data.data.baseRole);

      setUserData(data)
      setIsLoading(false);
    });
  };

  const checkLocation = () => {
    if (window.location.pathname === "/quick-start")  return true;
    return false;
  }

  useEffect(() => {
    getSelf();
  }, []);

  return (
    <>
      {!showInfo && (
        <UserContext.Provider value={{ userData, setUserData }}>
          {isLoading && !showInfo ? (
            <LandingPageLayout>
              <AppLoader />
            </LandingPageLayout>
          ) : (
            props.children
          )}
        </UserContext.Provider>
      )}
      {showInfo && !checkLocation() && <WarningPage />}
      {showInfo && checkLocation() && <AppInstruction />}
    </>
  );
}

export default UserContext;
