import { Button, makeStyles } from '@fluentui/react-components';
import {
  TableCell,
  TableCellLayout,
  TableRow,
  TableRowData as RowStateBase,
} from '@fluentui/react-components/unstable';
import { useContext, useState } from 'react';
import { ListChildComponentProps } from 'react-window';
import useStatusStyle from '../../hooks/useStatusStyle';
import UserContext from '../../modules/AppRouter/UserContext';
import { overtimeAssigments } from '../../services';
import dataTimeService from '../../services/dataTime.service';
import dateFormatService from '../../services/dateFormat.service';
import translateService from '../../services/translateService';
import { IAssignmentsTabRow } from '../../services/types/table';
import { applicationTypeEnum, assignmentStatus, documentTypeEnum, overtimeTypeEnum, userRoleEnum } from '../../utils/enums';
import { AppConfirmdialog } from '../common';

interface ReactWindowRenderFnProps extends ListChildComponentProps {
  data: TableRowData[];
}
interface TableRowData extends RowStateBase<IAssignmentsTabRow> {
  onClick: (e: React.MouseEvent) => void;
  selected: boolean;
  appearance: 'brand' | 'none';
  getAssignments: () => void
}

const useStyles = makeStyles({
  tableCell: {
    minWidth: "126px"
  },
})

const AssignmentListRow = ({
  index,
  style,
  data,
}: ReactWindowRenderFnProps) => {
  const { item, appearance, onClick, getAssignments } = data[index];
  const { checkAssignmentStatus } = useStatusStyle();
  const [ openDialog, setOpenDialog ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useContext(UserContext);
  const classes = useStyles();

  const deleteAssignment = () => {
    setIsLoading(true);
    overtimeAssigments.deleteAssignment(item.id)
      .then(() => {
        getAssignments();
      }).finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <TableRow
        aria-rowindex={index + 2}
        style={style}
        onClick={onClick}
        appearance={appearance}
        key={item.id}
      >
        <TableCell className={classes.tableCell}>
          <TableCellLayout>{`${item.employee.lastName} ${item.employee.firstName}`}</TableCellLayout>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <TableCellLayout>{`${dataTimeService.convertFormat(
            item.duration
          )}`}</TableCellLayout>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <TableCellLayout>{`${dateFormatService.formatDate(
            item.assignmentDate
          )}`}</TableCellLayout>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <TableCellLayout
            className={checkAssignmentStatus(item.status)}
          >{`${translateService.translateStatus(item.status)}`}</TableCellLayout>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <TableCellLayout>
            {item.documentType === documentTypeEnum.Commision 
            ? `Zlecenie ${item.applicationType === applicationTypeEnum.Overtime ? "nadgodzin" : "wyjścia"}` 
            : `Wniosek o ${item.applicationType === applicationTypeEnum.Overtime ? "nadgodziny" : "wyjście"}`}
          </TableCellLayout>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <TableCellLayout>
            {item.type === overtimeTypeEnum.TimeToPay ? "Do wypłaty" : "Do odbioru"}
          </TableCellLayout>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <TableCellLayout>{`${dateFormatService.formatDate(
            item.createTime
          )}`}</TableCellLayout>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <TableCellLayout>
            <Button 
              onClick={(e) => {
                e.stopPropagation();
                setOpenDialog(true)
              }}
              disabled = {item.status === assignmentStatus.Realized || 
                (userData.data.baseRole === userRoleEnum.Employee 
                && (item.status !== assignmentStatus.New || item.documentType !== documentTypeEnum.Application))}
            >
              Usuń wpis
            </Button>
          </TableCellLayout>
        </TableCell>
      </TableRow>
      <AppConfirmdialog
        title='Potwierdź usunięcie'
        bodyTxt='Czy na pewno chcesz usunąć ten wpis?'
        setOpen={setOpenDialog}
        open={openDialog}
        onAccept={() => deleteAssignment()}
        isLoading={isLoading}
      />
    </>
  );
};

export default AssignmentListRow;
