import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    height: "16px",
    width: "100%",
  },
});

export default function AppDivider() {
  const classes = useStyles();
  return <div className={classes.root} />;
}
