import axiosInstance from "../utils/axios";
import dateFormatService from "./dateFormat.service";

interface GetWorktimeQuery {
  requestDate: Date;
}

interface GetInAndOutQuery {
  dateFrom: Date;
  dateTo: Date | null | undefined;
}

export interface WorktimeWrapperDto {
  worktimes: WorktimeInAndOut[];
  totalWorkTime: string;
}

export interface Worktime {
  startTimeWorktime: string | null;
  endTimeWorktime: string | null;
  isActive: boolean;
  isFinished: boolean;
  totalWorktimeFormatted: string;
  estimatedLeaveTime: string;
}

export interface WorktimeInAndOut {
  startTime: string;
  startTimeDate: string;
  endTime: string | null;
  endTimeDate: string | null;
  worktimeSpan: string;
}

export interface UserActivity {
  firstName: string;
  lastName: string;
  isActive: boolean;
  email: string;
}

const pathPrefix = `${process.env.REACT_APP_SERVER_URL}/api/work-times`;

export const GetActivityStatus = async (): Promise<UserActivity[]> => {
  const result = await axiosInstance.get<UserActivity[]>(
    `${pathPrefix}/status`
  );

  return result.data;
};

export const GetWorktimeAsync = async (
  getWorktimeQuery: GetWorktimeQuery
): Promise<Worktime> => {
  const formatedDate = dateFormatService.formatDate(
    getWorktimeQuery.requestDate.toString()
  );
  const result = await axiosInstance.get<Worktime>(pathPrefix, {
    params: {
      requestDate: formatedDate,
    },
  });

  return result.data;
};

export const GetInAndOutAsync = async (
  getWorktimeQuery: GetInAndOutQuery
): Promise<WorktimeWrapperDto> => {
  const formatedDateFrom = dateFormatService.formatDate(
    getWorktimeQuery.dateFrom.toString()
  );
  const formatedDateTo = getWorktimeQuery.dateTo ? dateFormatService.formatDate(
    getWorktimeQuery.dateTo.toString()
  ) : null;
  const result = await axiosInstance.get<WorktimeWrapperDto>(
    `${pathPrefix}/in-out`,
    {
      params: {
        dateFrom: formatedDateFrom,
        dateTo: formatedDateTo
      },
    }
  );

  return result.data;
};

export const CreateWorktimeAsync = async () => {
  return await axiosInstance.post(pathPrefix);
};

export const PatchWorktimeAsync = async () => {
  return await axiosInstance.put(pathPrefix, {
    params: {},
  });
};
