import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import TabConfig from "../TabConfig";
import AdminPage from "../AdminPage/AdminPage";
import EmployeePage from "../EmployeePage/EmployeePage";
import { UserContextProvider } from "./UserContext";
import UserContext from "./UserContext";
import ManageEmployees from "../AdminPage/Subpages/ManageEmployees";
import ManageAssigments from "../AdminPage/Subpages/ManageAssigments";
import ManageRaports from "../AdminPage/Subpages/ManageRaports";
import AssignmentDetails from "../AdminPage/Subpages/AssignmentDetails";
import CollectedTimeHistory from "../Shared/CollectedTimeHistory";
import AppInstruction from "../Shared/AppInstruction";
import LeavingsHistory from "../Shared/LeavingsHistory";
import WorktimeOverview from "../Shared/WokrtimeOverview/WorktimeOverview";
import DesktopWorktimeLayout from "../Shared/DesktopWorktimeAndActivityLayout/DesktopWorktimeLayout";
import MobileInAndOutPage from "../../components/Pages/MobileInAndOutPage";
import MobilityActivityStatusPage from "../../components/Pages/MobileActivityStatusPage";
import WorktimeHistory from "../AdminPage/Subpages/WorktimeHistory";
import WorktimeHistoryWithinDay from "../AdminPage/Subpages/WorktimeHistoryWithinDay";

export default function AppRouter() {
  return (
    <>
      <UserContextProvider>
        <UserContext.Consumer>
          {({ userData }) => (
            <>
              <Router>
                <Route exact path="/">
                  {userData.data.baseRole === "Admin" ? (
                    <Redirect to="/admin" />
                  ) : (
                    <Redirect to="/worktime" />
                  )}
                </Route>
                <Route path="/admin">
                  {userData.data.baseRole === "Admin" ? (
                    <Route exact path="/admin">
                      <AdminPage />
                    </Route>
                  ) : (
                    <Redirect to="/" />
                  )}
                </Route>
                {userData.data.organization !== null ? (
                  <>
                    <Route
                      path="/admin/manage-assigments"
                      component={ManageAssigments}
                    />
                    <Route
                      path="/assignment-details/:id/:number"
                      component={AssignmentDetails}
                    />
                    <Route
                      path="/admin/manage-employees"
                      component={ManageEmployees}
                    />
                    <Route path="/admin/raports" component={ManageRaports} />
                    <Route
                      exact
                      path="/admin/registered-time-history"
                      component={WorktimeHistory}
                    />
                    <Route
                      path="/admin/registered-time-history/:id/details"
                      component={WorktimeHistoryWithinDay}
                    />
                    <Route
                      path="/collected-time-history"
                      component={CollectedTimeHistory}
                    />
                    <Route
                      path="/leavings-history"
                      component={LeavingsHistory}
                    />
                  </>
                ) : null}
                <Route exact path="/employee" component={EmployeePage} />
                <Route exact path="/worktime" component={WorktimeOverview} />
                <Route exact path="/inAndOut" component={MobileInAndOutPage} />
                <Route
                  exact
                  path="/activityStatus"
                  component={MobilityActivityStatusPage}
                />
                <Route
                  exact
                  path="/desktopWorktime"
                  component={DesktopWorktimeLayout}
                />
                <Route exact path="/config" component={TabConfig} />
                <Route exact path="/quick-start" component={AppInstruction} />
              </Router>
            </>
          )}
        </UserContext.Consumer>
      </UserContextProvider>
    </>
  );
}
