import { LargeTitle, makeStyles } from '@fluentui/react-components';
import React from 'react';

const useStyles = makeStyles({
  title: {
    marginTop: '8rem',
    marginBottom: '1rem',
    zIndex: '2',
    alignSelf: 'flex-start',
    marginLeft: '3.5%',
    color: 'white',
  },
});

const PageTitle: React.FC<{ title: string }> = ({ title }) => {
  const styles = useStyles();
  return <LargeTitle className={styles.title}>{title}</LargeTitle>;
};

export default PageTitle;
