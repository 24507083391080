import { AxiosError } from "axios";
import { AppErrorToast } from "../components/common";

export default function errorHandler(error: AxiosError) {
  if (error.response?.status === 400) {
    handleBadRequest(error); 
  } else if (error.response?.status === 401) {
    AppErrorToast("Nieautoryzowany dostęp");
  } else if (error.response?.status === 404) {
    AppErrorToast("Błąd komunikacji z serwerem");
  } else if (error.response?.status === 500) {
    AppErrorToast("Błąd serwera");
  } else {
    AppErrorToast("Błąd zapytania");
  }
}

const handleBadRequest = (error: AxiosError) => {
  switch (error.response?.data.Code) {
    case "worktime_edition_failure":
      AppErrorToast("Przedział czasu pracy pokrywa się z okresami innych czasów pracy");
      break;    
    case "invalid_worktime_span":
      AppErrorToast("Godzina rozpoczęcia okresu pracy musi być wcześniejsza niż godzina zakończenia");
      break;
    default:
      AppErrorToast("Nieprawidłowe zapytanie");
      break;
  } 
}
