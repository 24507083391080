import React, { useContext } from 'react'
import PageTitle from '../../components/common/PageTitle'
import { CenteredLayout } from '../../components/layouts'
import AdminNavbar from '../../components/layouts/AdminNavbar'
import { userRoleEnum } from '../../utils/enums'
import UserContext from '../AppRouter/UserContext'
import useLeavings from '../../hooks/useLeavings'
import LeavingsHistoryList from '../../components/layouts/LeavingsHistoryList'

const LeavingsHistory: React.FC = () => {
   const { leavings, getLeavings, isLoading } = useLeavings()
   const { userData } = useContext(UserContext);

   return (
      <>
      {userData.data.baseRole === userRoleEnum.Admin &&
         <AdminNavbar/>
      }
         <CenteredLayout>
            <PageTitle title = "Historia wyjść" />
               <div className = 'table-container'>
                  <LeavingsHistoryList 
                     tableHeadTxt = 'Historia wyjść'
                     isLoading = {isLoading}
                     getMoreItems = {getLeavings}
                     items = {leavings}
                  />
               </div>
         </CenteredLayout>
      </>
  )
}

export default LeavingsHistory
