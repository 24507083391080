import {
  makeStyles,
  shorthands,
  Body1,
  Input,
  useId,
  Label,
  Select,
} from "@fluentui/react-components";
import { Card } from "@fluentui/react-components/unstable";
import moment from "moment";
import { useEffect, useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { CenteredLayout } from "../../../components/layouts";
import AdminNavbar from "../../../components/layouts/AdminNavbar";
import dataTimeService from "../../../services/dataTime.service";
import { reportsService, usersService } from "../../../services";
import { AppButton } from "../../../components/common";
import { AppUserSelect } from "../../../components/common";
import { IUserSelectable } from "../../../services/types/users";
import appErrorToast from "../../../components/common/AppErrorToast";

const useStyles = makeStyles({
  card: {
    width: "93vw",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
  cardBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    "@media (max-width: 966px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  button: {
    maxHeight: "2rem",
    marginInlineStart: "auto",
  },
  subtitle: {
    position: "relative",
    color: 'white'
  }
});

const ManageRaports: React.FC = () => {
  const styles = useStyles();
  const [mobile, setMobile] = useState<boolean>(window.innerWidth <= 719);

  const inputMonthId = useId("inputMonthId");
  const currentMonth = dataTimeService.todayString(); 
  const [date, setDate] = useState<string[]>([
    currentMonth,
    currentMonth,
    currentMonth,
  ]);

  const [isLoading, setIsLoading] = useState([false, false, false]);
  const [inputType, setInputType] = useState(['YYYY-MM', 'YYYY-MM']);
  const [inputUser, setInputUser] = useState('Brak wyboru');
  const [userOptions, setUserOptions] = useState<IUserSelectable[]>([]);

  const weekFormat = 'YYYY-[W]WW';
  const monthFormat = 'YYYY-MM';
  const dayFormat = 'YYYY-MM-DD';

  const updateState = (
    index: number,
    value: any,
    setter: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    setter(prevState => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    index: number,
    setter: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    const value = e.target.value;
    updateState(index, value, setter);
  };

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  }

  const generateUserReport = () => {
    if (inputUser === 'Brak wyboru') {
      appErrorToast(
        "Nie można wygenerować raportu, ponieważ nie wybrałeś pracownika"
      );
      return;
    }

    const isLoadingSetter = (value: boolean) => updateState(1, value, setIsLoading);
    isLoadingSetter(true);

    reportsService.generateWorktimeReport(
      moment(date[1]).format(inputType[0]), 
      isLoadingSetter,
      inputUser, 
    );
  };

  const generateSummaryRaport = () => {
    const isLoadingSetter = (value: boolean) => updateState(2, value, setIsLoading);
    isLoadingSetter(true);
    let variableDate = moment(date[2]).format(inputType[1])//

    console.log(variableDate);//

    reportsService.generateWorktimeReport(
      moment(date[2]).format(inputType[1]), 
      isLoadingSetter,
      );
  };

  const generateRaport = () => {
    const isLoadingSetter = (value: boolean) => updateState(0, value, setIsLoading);
    isLoadingSetter(true);

    const month = dataTimeService.getMonth(date[0]);
    const year = dataTimeService.getYear(date[0]);
    reportsService.generateMonthlyReport(year, month + 1, isLoadingSetter);
  }


  useEffect(() => {
    usersService.selectableUsers().then((users) => {
      const defaultValue: IUserSelectable = {id: "", firstName: "Brak wyboru", lastName: "" }
      setUserOptions([defaultValue, ...users]);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <>
      <AdminNavbar />
      <CenteredLayout>
        <PageTitle title="Raporty" />
        <div className='table-container'>
          <h2 className={styles.subtitle}>Nadgodziny</h2>
          <Card className={styles.card}>
            <h3>Generuj raport miesięczny nadgodzin</h3>
            <Body1 className={styles.cardBody}>
              <div className={styles.form}>
                <Label htmlFor={inputMonthId}>Wybierz miesiąc:</Label>
                <Input
                  type="month"
                  max={moment().format(monthFormat)}
                  value={moment(date[0]).format(monthFormat)}
                  onChange={(e) => onInputChange(e, 0, setDate)}
                  id={inputMonthId}
                />
              </div>
              <div style={{ margin: "0.5em" }}></div>
              <AppButton
                text="Generuj raport"
                className={styles.button}
                disabled={isLoading[0]}
                appearance="primary"
                onClick={generateRaport}
              />
            </Body1>
          </Card>
          <div style={{ margin: "2rem" }}></div>
          <h2>Czas pracy</h2>
          <Card className={styles.card}>
            <h3>Generuj raport czasu pracy dla pracownika</h3>
            <Body1 className={styles.cardBody}>
              <div className={styles.form}>
                <Label htmlFor="per-user-input">Wybierz typ:</Label>
                <Select
                  id="per-user-input"
                  value={inputType[0]}
                  onChange={(e) => onInputChange(e, 0, setInputType)}
                >
                  <option value={monthFormat}>Miesięczny</option>
                  <option value={dayFormat}>Data</option>
                </Select>
                {inputType[0] === monthFormat && (
                  <>
                    <Label htmlFor={inputMonthId}>Wybierz miesiąc:</Label>
                    <Input
                      type="month"
                      max={moment().format(monthFormat)}
                      value={moment(date[1]).format(monthFormat)}
                      onChange={(e) => onInputChange(e, 1, setDate)}
                    />
                  </>
                )}
                {inputType[0] === dayFormat && (
                  <>
                    <Label htmlFor={inputMonthId}>Wybierz datę:</Label>
                    <Input
                      type="date"
                      max={moment().format(dayFormat)}
                      value={moment(date[1]).format(dayFormat)}
                      onChange={(e) => onInputChange(e, 1, setDate)}
                    />
                  </>
                )}
                <AppUserSelect
                  label="Wybierz pracownika"
                  options={userOptions}
                  onChange={(e) => setInputUser(e.target.value)}
                  formHandler={{}}
                />
              </div>
              <div style={{ margin: "0.5em" }}></div>
              <AppButton
                text="Generuj raport dla użytkownika"
                className={styles.button}
                disabled={isLoading[1]}
                appearance="primary"
                onClick={generateUserReport}
              />
            </Body1>
          </Card>
          <div style={{ margin: "2rem" }}></div>
          <Card className={styles.card}>
            <h3>Generuj zbiorczy raport czasu pracy</h3>
            <Body1 className={styles.cardBody}>
              <div className={styles.form}>
                <Label htmlFor="input-type">Wybierz typ:</Label>
                <Select
                  id="input-type"
                  value={inputType[1]}
                  onChange={(e) => onInputChange(e, 1, setInputType)}
                >
                  <option value={monthFormat}>Miesięczny</option>
                  <option value={weekFormat}>Tygodniowy</option>
                  <option value={dayFormat}>Data</option>
                </Select>

                {inputType[1] === monthFormat && (
                  <>
                    <Label htmlFor={inputMonthId}>Wybierz miesiąc:</Label>
                    <Input
                      type="month"
                      max={moment().format(monthFormat)}
                      value={moment(date[2]).format(monthFormat)}
                      onChange={(e) => onInputChange(e, 2, setDate)}
                    />
                  </>
                )}
                {inputType[1] === weekFormat && (
                  <>
                    <Label htmlFor={inputMonthId}>Wybierz tydzień:</Label>
                    <Input
                      type="week"
                      max={moment().format(weekFormat)}
                      value={moment(date[2]).format(weekFormat)}
                      onChange={(e) => onInputChange(e, 2, setDate)}
                    />
                  </>
                )}
                {inputType[1] === dayFormat && (
                  <>
                    <Label htmlFor={inputMonthId}>Wybierz datę:</Label>
                    <Input
                      type="date"
                      max={moment().format(dayFormat)}
                      value={moment(date[2]).format(dayFormat)}
                      onChange={(e) => onInputChange(e, 2, setDate)}
                    />
                  </>
                )}
              </div>
              <div style={{ margin: "0.5em" }}></div>

              <AppButton
                text="Generuj raport zbiorczy"
                className={styles.button}
                disabled={isLoading[2]}
                appearance="primary"
                onClick={generateSummaryRaport}
              />
            </Body1>
          </Card>
          <div style={{ margin: "2rem" }}></div>
        </div>
      </CenteredLayout>
    </>
  );
};
export default ManageRaports;