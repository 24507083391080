import { Button } from '@fluentui/react-components';
import {
  TableCell,
  TableCellLayout,
  TableRow,
  TableRowData as RowStateBase,
} from '@fluentui/react-components/unstable';
import { useContext, useState } from 'react';
import { ListChildComponentProps } from 'react-window';
import dataTimeService from '../../services/dataTime.service';
import dateFormatService from '../../services/dateFormat.service';
import { ICollectedTimeSummary } from '../../services/types/table';
import UserContext from '../../modules/AppRouter/UserContext'
import { userRoleEnum } from '../../utils/enums';
import { overtimeSummariesService } from '../../services';
import { AppConfirmdialog } from '../common';

interface ReactWindowRenderFnProps extends ListChildComponentProps {
  data: TableRowData[];
}
interface TableRowData extends RowStateBase<ICollectedTimeSummary> {
  getCollectedTime: () => void;
  selected: boolean;
  appearance: 'brand' | 'none';
}

const CollectedTimeListRow = ({
  index,
  style,
  data,
}: ReactWindowRenderFnProps) => {
  const { item, appearance, getCollectedTime } = data[index];
  const [openDialog, setOpenDialog] = useState(false);
  const { userData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const deleteRegistry = () => {
    setIsLoading(true);
    overtimeSummariesService.deleteCollectedTime(item.id)
    .then(() => {
      getCollectedTime();
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <TableRow
      aria-rowindex={index + 2}
      style={style}
      appearance={appearance}
      key={item.id}
    >
      <TableCell>
        <TableCellLayout>{`${item?.employee?.lastName} ${item?.employee?.firstName}`}</TableCellLayout>
      </TableCell>
      <TableCell>
        <TableCellLayout>{`${dataTimeService.convertToTimeFormat(item.collectedTime)}`}</TableCellLayout>
      </TableCell>
      <TableCell>
        <TableCellLayout>{`${dateFormatService.formatDate(item.date)}`}</TableCellLayout>
      </TableCell>
      {userData.data.baseRole === userRoleEnum.Admin &&
      <TableCell>
        <TableCellLayout>
          <Button onClick={() => {setOpenDialog(true)}}>Usuń wpis</Button>
          </TableCellLayout>
        </TableCell>
      }
    <AppConfirmdialog
      title='Potwierdź usunięcie wpisu'
      bodyTxt='Czy na pewno chcesz usunąć ten wpis?'
      setOpen={setOpenDialog}
      open={openDialog}
      onAccept={deleteRegistry}
      isLoading={isLoading}
    />
    </TableRow>
  );
};

export default CollectedTimeListRow;
