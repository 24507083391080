import AdminNavbar from "../../../components/layouts/AdminNavbar";
import StartStopWorkButton from "../../../components/common/StartStopWorkButton/StartStopWorkButton";
import { AssignemntCardRow } from "../../../components/layouts";
import { Body1, Button, Spinner, Subtitle2 } from "@fluentui/react-components";
import useWorktime from "../../../hooks/useWorktime";
import { Background } from "../../../components/common";
import "./WorktimeOverviewStyles.css";
import { useCallback, useEffect, useState } from "react";
import { Card } from "@fluentui/react-components/unstable";
import { useHistory } from "react-router-dom";
import { mobileWidth } from "../../../services/Helpers/HelperService";

const WorktimeOverview = () => {
  const history = useHistory();
  const { active, progress, worktime, isLoading, triggerButtonState } =
    useWorktime();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const handleNavigation = useCallback((route: string) => {
    history.push(`/${route}`);
  }, []);

  const onResize = useCallback(() => {
    setInnerWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return (
    <>
      <Background />
      <AdminNavbar />
      <div className="worktime-container">
        <div className="button-and-header">
          <h1 className="work-time-header">Rejestracja czasu pracy</h1>
          <StartStopWorkButton
            active={active}
            progress={progress}
            triggerStartStopButton={triggerButtonState}
            isDisabled={isLoading}
          />
        </div>
        <div className="card-container">
          {isLoading ? (
            <Spinner
              size="huge"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            />
          ) : (
            <Card className="card">
              <Body1>
                <AssignemntCardRow
                  title="Aktualny czas pracy"
                  txt={worktime.totalWorktimeFormatted}
                />

                <AssignemntCardRow
                  title="Wejście"
                  txt={worktime.startTimeWorktime}
                />
                {!worktime.isFinished 
                  ? <AssignemntCardRow
                    title="Przewidywane godziny pracy"
                    txt={`${worktime.startTimeWorktime} - ${worktime.estimatedLeaveTime}`}
                  /> 
                  : <Subtitle2>Przewidywany czas pracy osiągnięty.</Subtitle2>}
              </Body1>
              {innerWidth <= mobileWidth ? (
                <>
                  <Button
                    appearance="primary"
                    onClick={() => {
                      handleNavigation("inAndOut");
                    }}
                  >
                    Historia Wejść/Wyjść
                  </Button>
                  <Button
                    appearance="primary"
                    onClick={() => {
                      handleNavigation("activityStatus");
                    }}
                  >
                    Obecność w pracy
                  </Button>
                </>
              ) : (
                <Button
                  appearance="primary"
                  onClick={() => {
                    handleNavigation("desktopWorktime");
                  }}
                >
                  Szczegóły
                </Button>
              )}
            </Card>
          )}
        </div>
      </div>
    </>
  );
};

export default WorktimeOverview;
