import { toast } from "react-hot-toast";
import { tokens } from "@fluentui/react-components";
import CSS from "csstype";

const styles: CSS.Properties = {
  color: tokens.colorPaletteLightGreenForeground1,
  background: tokens.colorPaletteLightGreenBackground1,
  borderRadius: tokens.borderRadiusMedium,
};

export default function appSuccessToast(text: string) {
  return toast(text, { style: styles });
}
