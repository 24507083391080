import {
  Body1,
  Body2,
  Button,
  Link,
  Subtitle2,
} from "@fluentui/react-components";
import { Card, CardFooter } from "@fluentui/react-components/unstable";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { IAssignmentDetails } from "../../../services/types/overtimeAssigments";
import {
  dataTimeService,
  dateFormatService,
  translateService,
  overtimeAssigments,
} from "../../../services";
import { applicationTypeEnum, assignmentStatus, documentTypeEnum, overtimeTypeEnum, userRoleEnum } from "../../../utils/enums";
import {
  AssignemntCardRow,
  CenteredLayout,
  RealizeAssignmentDialog,
  RejectAssignmentDialog,
  UnrealizedAssignmentDialog,
} from "../../../components/layouts";
import { AppButton, AppLoader } from "../../../components/common";
import UserContext from "../../AppRouter/UserContext";
import useStyles from "./AssignmentDetailsStyles";
import appErrorToast from "../../../components/common/AppErrorToast";
import ModifyAssignmentDialog from "../../../components/layouts/ModifyAssignmentDialog";
import { Edit24Regular } from "@fluentui/react-icons";

const AssignmentDetails = () => {
  const { id, number } = useParams<{ id: string; number: string }>();
  const [assignmentDetails, setAssignmentDetails] = useState<IAssignmentDetails>();
  const [open, setOpen] = useState(false);
  const [modifyOpen, setModifyOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const { userData } = useContext(UserContext);
  const [realizeDialogOpen, setRealizeDialogOpen] = useState(false);
  const [unrealizedDialogOpen, setUnerealizedDialogOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const styles = useStyles();

  const acceptAssignment = () => {
    setIsAccepting(true);
    const status = assignmentStatus.Accepted;
    const description = "";
    overtimeAssigments.updateAssignment(status, description, id)
    .then(() => {
      setReload(!reload);
    }).finally(() => {
      setIsAccepting(false);
    });
  };

  const goBack = () => {
    window.history.back();
  }

  const rejectAssignment = (rejectionDescription: string, setIsRejecting: Dispatch<SetStateAction<boolean>>) => {
    if (rejectionDescription.length > 1) {
      setIsRejecting(true);
      const status = assignmentStatus.Rejected;
      overtimeAssigments.updateAssignment(status, rejectionDescription, id)
      .then(() => {
        setOpen(false);
        setReload(!reload);
      }).finally(() => {
        setIsRejecting(false);
      });
    } else {
      appErrorToast("Podaj powód odrzucenia");
    }
  };

  useEffect(() => {
    setAssignment();
  }, [reload, id]);

  const setAssignment = () => {
    setIsLoading(true);
    overtimeAssigments.getAssignmentDetails(id).then((result) => {
      setAssignmentDetails(result);
      setIsLoading(false);
    });
  }

  return (
    <CenteredLayout>
      <RejectAssignmentDialog
        open={open}
        setOpen={setOpen}
        rejectAssignment={rejectAssignment}
      />
      {assignmentDetails && 
      <ModifyAssignmentDialog 
        open={modifyOpen}
        setOpen={setModifyOpen}
        formData={{
          employeeId: userData.data.id,
          assigmentDate: new Date(assignmentDetails.assignmentDate),
          duration: assignmentDetails.duration.toString(),
          type: null,
          applicationType: assignmentDetails.applicationType,
          assignmentDescription: assignmentDetails?.assignmentDescription 
            ? assignmentDetails.assignmentDescription 
            : "",
          generateApplication: assignmentDetails.generateApplication
        }}
        refresh={setAssignment}
      />}
      <div className={styles.cardsContainer}>
        <Card className={styles.card}>
          <div className={styles.headerContainer}>
            <Button 
              appearance="primary"
              onClick={goBack}
              className={styles.button}
            >
              Powrót
            </Button>
            {(userData.data.baseRole === userRoleEnum.Employee 
              && assignmentDetails?.status === assignmentStatus.New
              && assignmentDetails.documentType === documentTypeEnum.Application) && 
            <Edit24Regular 
              className={styles.editBtn}
              onClick={() => { setModifyOpen(true); }}
            />}
          </div>
          <h2>{assignmentDetails?.documentType === documentTypeEnum.Commision ? "Zlecenie" : "Wniosek"} nr {parseInt(number) + 1}</h2>
          <Body1 className={styles.cardBodyContainer}>
            <Body2 className={styles.cardBody}>
              {isLoading ? (
                <AppLoader />
              ) : (
                <>
                  <AssignemntCardRow
                    title="Pracownik"
                    txt={`${assignmentDetails?.employee.lastName} ${assignmentDetails?.employee.firstName}`}
                  />
                  <AssignemntCardRow
                    title="Data utworzenia"
                    txt={
                      assignmentDetails &&
                      dateFormatService.formatDate(assignmentDetails.createTime)
                    }
                  />
                  <AssignemntCardRow
                    title="Data realizacji"
                    txt={
                      assignmentDetails &&
                      dateFormatService.formatDate(assignmentDetails.assignmentDate)
                    }
                  />
                  <AssignemntCardRow
                    title="Liczba godzin"
                    txt={dataTimeService.convertFormat(
                      assignmentDetails?.duration
                    )}
                  />
                  {assignmentDetails?.applicationType !== applicationTypeEnum.Leaving &&
                  <AssignemntCardRow
                    title="Typ"
                    txt={translateService.translateType(
                      assignmentDetails?.type
                    )}
                    />}
                  {assignmentDetails?.documentType === documentTypeEnum.Application &&
                  <AssignemntCardRow
                    title="Rodzaj wniosku"
                    txt={`${translateService.translateAppplicationType(
                      assignmentDetails?.applicationType
                    )} ${assignmentDetails?.generateApplication ? "(Odrabiam dzisiaj)" : ""}`}
                  />}
                  {assignmentDetails?.assignmentDescription && (
                    <div className={styles.assignmentDescription}>
                      <Subtitle2>Opis:</Subtitle2>
                      {assignmentDetails?.assignmentDescription}
                    </div>
                  )}
                  {assignmentDetails?.generatedAssignment && (
                    <div className={styles.assignmentDescription}>
                      <Subtitle2>Rozliczenie wyjścia:</Subtitle2>
                      <NavLink
                        to={`/assignment-details/${assignmentDetails?.generatedAssignment.id}/${number}`}
                      >
                        Przekieruj do rozliczenia odpracowania tego wyjścia
                      </NavLink>
                    </div>
                  )}
                  <AssignemntCardRow
                    title="Status"
                    txt={translateService.translateStatus(
                      assignmentDetails?.status
                    )}
                  />
                  {assignmentDetails?.rejectionDescription && (
                    <div className={styles.assignmentDescription}>
                      <Subtitle2>Powód:</Subtitle2>
                      {assignmentDetails?.rejectionDescription}
                    </div>
                  )}
                  {((userData.data.baseRole === userRoleEnum.Admin &&
                    assignmentDetails?.status === assignmentStatus.New &&
                    assignmentDetails?.documentType === documentTypeEnum.Application
                    ) ||
                    (assignmentDetails?.documentType === documentTypeEnum.Commision &&
                    assignmentDetails?.status === assignmentStatus.New &&
                    userData.data.baseRole === userRoleEnum.Employee)) && (
                      <CardFooter className={styles.btnsContainer}>
                        <AppButton
                          text="Akceptuj"
                          disabled={isAccepting}
                          appearance="primary"
                          onClick={acceptAssignment}
                        />
                        <Button onClick={() => setOpen(true)}>Odrzuć</Button>
                      </CardFooter>
                    )}
                  {assignmentDetails?.status === assignmentStatus.Accepted &&
                    userData.data.baseRole !== userRoleEnum.Admin && (
                      <CardFooter className={styles.btnsContainer}>
                        <Button
                          appearance="primary"
                          onClick={() => setRealizeDialogOpen(true)}
                        >
                          Rozlicz
                        </Button>
                        <Button onClick={() => setUnerealizedDialogOpen(true)}>
                          Nie zrealizowano
                        </Button>
                      </CardFooter>
                    )}
                </>
              )}
            </Body2>
          </Body1>
        </Card>
        {assignmentDetails?.realization && (
          <Card className={styles.realizationCard}>
            <Body1>
              <Body2 className={styles.cardBody}>
                <>
                  <AssignemntCardRow
                    title="Data realizacji"
                    txt={dateFormatService.formatDate(
                      assignmentDetails.realization.realizationDate
                    )}
                  />
                  <AssignemntCardRow
                    title="Zrealizowano"
                    txt={dataTimeService.convertFormat(
                      assignmentDetails.realization.duration
                    )}
                  />
                  {assignmentDetails.realization.urls.length > 0 && (
                  <>
                    <AssignemntCardRow title="Zrealizowane zadania" />
                      <ul className={styles.listItem}>
                        {assignmentDetails.realization.urls &&
                          assignmentDetails.realization.urls.map((e, index) => {
                            return (
                              <li key={index}>
                                <Link appearance="subtle" target="_blank" href={e.url}>
                                  {e.url}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </>
                  )}
                </>
              </Body2>
            </Body1>
          </Card>
        )}
      </div>
      <RealizeAssignmentDialog
        open={realizeDialogOpen}
        setOpen={setRealizeDialogOpen}
        date={
          assignmentDetails &&
          dateFormatService.formatDate(assignmentDetails.createTime)
        }
        assignmentDuration={dataTimeService.convertFormat(
          assignmentDetails?.duration
        )}
        setReload={setReload}
        reload={reload}
        isCommision={assignmentDetails?.documentType === documentTypeEnum.Commision}
        isOvertime={assignmentDetails?.applicationType === applicationTypeEnum.Overtime}
      />
      <UnrealizedAssignmentDialog
        open={unrealizedDialogOpen}
        setOpen={setUnerealizedDialogOpen}
        setReload={setReload}
        reload={reload}
      />
    </CenteredLayout>
  );
};

export default AssignmentDetails;
